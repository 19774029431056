import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import 'react-responsive-modal/styles.css';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import Box from '@mui/material/Box';
import '../../../assets/styles/bootstrap.min.css';
import MenuItem from '@material-ui/core/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { useDispatch, useSelector } from 'react-redux';
import {
  CustomField,
  CustomFields_State,
} from 'Redux/Reducers/opportunity/opportunityCustomFields.reducer';
import {
  Contact,
  CustomFields,
  Source,
  ContactState,
} from 'Redux/Reducers/opportunity/contacts.reducer';

import { CustomFields as CustomFieldsMain } from 'Redux/Reducers/opportunity/opportunityCustomFields.reducer';
import { Actions } from 'Redux/Actions';
import { FetchContactsQueryParams } from './Contacts';
import { AccountsState } from 'Redux/Reducers/opportunity/accounts.reducer';
import CustomSearchableDropdown from 'Components/CustomDropdowns/CustomSearchableDropdown';
import { isValidEmail, isValidURL, validateSocialMediaUrl } from 'helpers/common';
import { OpportunityState } from 'Redux/Reducers/opportunity/opportunities.reducer';
import { createNotification } from 'helpers';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import OpportunityAccountsCustomFieldsAPI from 'Redux/API/opportunity/opportunityCustomFieldsAPI';
import OpportunityAccountsAPI from 'Redux/API/opportunity/opportunityAccountsAPI';
import EarlyAccessSubIcon from '../../../assets/images/icons/EarlyAccessremoveIcon.svg';
import OpportunityContactsAPI from 'Redux/API/opportunity/opportunityContactsAPI';
import Loader from 'Components/Loader/loader';
import OpportunityOpportunitiesAPI from 'Redux/API/opportunity/opportunityOpportunitiesAPI';
import AddIcon from '@material-ui/icons/Add';
import ConfirmationAlertPopUp from 'Common/ConfirmationAlertPopUp';
import moment from 'moment';
import DropdownForListDataTypeCF from '../Common/DropdownForListDataTypeCF';
import InputFieldForPhoneDataTypeCF from '../Common/InputFieldForPhoneDataTypeCF';
import CustomSearchableMultiSelectedDropdownWithLoadMore from 'Components/CustomDropdowns/CustomSearchableMultiSelectDropdownWithLoadMore';
import { AudienceState } from 'Redux/Reducers/opportunity/audience.reducer';
import ReactSelect from 'react-select';
import { customStyles } from 'Pages/Dashboard/Notications/addNotification';
import { Option } from 'Pages/Dashboard/Notications/addNotification';
import { getActiveUsersForCompanyAPI } from 'Redux/API';
import StateDropdown from '../CommonComponents/StateDropdown';
import {
  OpportunityStates as OpportunityDynamicStates,
  OpportunityStates_State,
} from 'Redux/Reducers/opportunity/opportunityStates.reducer';
import ReactDatePicker from 'react-datepicker';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

type CustomFieldsValidationError = {
  [key: string]: boolean | string;
};

type CustomFieldsObj = {
  [key: string]: string | string[];
};

type AddOrUpdateContactProps = {
  setShowAddNewModal: React.Dispatch<React.SetStateAction<boolean>>;
  queryParams: FetchContactsQueryParams;
  // contactToBeEdited: Contact;
  resetAddNewModal: () => void;
  contactId: string;
  groupClickObject: any;
  setShowAddNewfromGroupTree: React.Dispatch<React.SetStateAction<boolean>>;
  bodyParamsForfiltering:any
};

type BodyParams = {
  groupId:string;
  isSmartGroup:boolean;
  filterArray: any;
  firstName: string;
  middleName: string;
  lastName: string;
  // fullName: string;
  email: string;
  customFields: CustomFields;
  _id?: string;
  source: Source;
  arrayOfAccountAndContactDataType: AccountAndContactDataTypeObj[];
  status: string;
  audienceIds: string[],
  groupsDetail: { _id: string, audienceName: string; }[],
  state?: string;
};

type BodyToCheckEmptyObject = {
  [key: string]: CustomFieldsInBody | string;
};

type CustomFieldsInBody = {
  [key: string]: string;
};

type AccountAndContactDataTypeObj = {
  accountId: string;
  contactDataTypeCfId: string;
};

type OpportunityAndContactDataTypeObj = {
  opportunityId: string;
  contactDataTypeCfId: string;
};

type AccountAndContactDataTypeValidationObj = {
  accountIdError?: boolean;
  contactDataTypeCfIdError?: boolean;
};

type OpportunityAndContactDataTypeValidationObj = {
  opportunityIdError?: boolean;
  contactDataTypeCfIdError?: boolean;
};

type PairToDelete = 'account' | 'opportunity';

type ContactGroup = {
  value: string;
  label: string;
};

type ContactGroups = ContactGroup[];

function AddOrEditContact({
  setShowAddNewModal,
  queryParams,
  // contactToBeEdited,
  resetAddNewModal,
  contactId,
  groupClickObject,
  setShowAddNewfromGroupTree,
  bodyParamsForfiltering
}: AddOrUpdateContactProps) {
  const initialRender1 = useRef(true);
  const initialRender2 = useRef(true);
  const initialRender3 = useRef(true);
  const initialRender4 = useRef(true);
  const { customFields } = useSelector(
    (state: any) => state.OpportunityCustomFields,
  ) as CustomFields_State;

  const { accounts } = useSelector(
    (state: any) => state.OpportunityAccounts,
  ) as AccountsState;

  const opportunityState = useSelector(
    (state: any) => state.OpportunityOpportunities,
  ) as OpportunityState;

  const {
    updateOpportunityContactSuccess,
    createOpportunityContactSuccess,
    openedContact,
  } = useSelector((state: any) => state.OpportunityContacts) as ContactState;

  const { activeUsersForCompany } = useSelector((state: any) => state.Users);

  const { groups } = useSelector(
    (state: any) => state.OpportunityAudience,
  ) as AudienceState;

  const {
    opportunityStates: opportunityDynamicStates,
    fetchAllOpportunityStateSuccess,
  } = useSelector(
    (state: any) => state.OpportunityStates,
  ) as OpportunityStates_State;

  const dispatch = useDispatch();
  const [customFieldsObj, setCustomFieldsObj] = useState<CustomFieldsObj>({});
  const [idBeingEdited, setIdBeingEdited] = useState<string>('');
  const [customFieldsValidationError, setCustomFieldsValidationError] =
    useState<CustomFieldsValidationError>({} as CustomFieldsValidationError);
  const [firstName, setFirstName] = useState<string>('');
  const [middleName, setMiddleName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [errors, setErrors] = useState<CustomFieldsValidationError>({});
  const [accountSearchList, setAccountSearchList] = useState<{}[] | null>(null);
  const [filterByAudience, setFilterByAudience] = useState<string[]>([]);
  const [selectedDynamicState, setSelectedDynamicState] = useState<string>('');
  const [dynamicStates, setDynamicStates] = useState<OpportunityDynamicStates>(
    [],
  );
  // const [contactSearchList, setContactSearchList] = useState<{}[] | null>(null);
  const [opportunitySearchList, setOpportunitySearchList] = useState<
    {}[] | null
  >(null);
  const [
    accountsCustomFieldsWithOnlyContactDataType,
    setAccountsCustomFieldsWithOnlyContactDataType,
  ] = useState<any>([] as any);

  const [accountContactCFSearchList, setAccountContactCFSearchList] = useState<
    {}[] | null
  >(null);
  const [opportunityContactCFSearchList, setOpportunityContactCFSearchList] =
    useState<{}[] | null>(null);
  const [account, setAccount] = useState([{}]);
  const [accountContactCF, setAccountContactCF] = useState([{}]);
  const [
    opportunityCustomFieldsWithOnlyContactDataType,
    setOpportunityCustomFieldsWithOnlyContactDataType,
  ] = useState<any>([] as any);
  const [opportunity, setOpportunity] = useState([{}]);
  const [opportunityContactCF, setOpportunityContactCF] = useState([{}]);
  const [email, setEmail] = useState<string>('');
  const [source, setSource] = useState<Source>('manuallyAdded');
  const [accountAndDataErrorsArr, setAccountAndDataErrorsArr] = useState<
    AccountAndContactDataTypeValidationObj[]
  >([]);
  const [opportunityAndDataErrorsArr, setOpportunityAndDataErrorsArr] =
    useState<OpportunityAndContactDataTypeValidationObj[]>([]);
  const [contactToBeEdited, setContactToBeEdited] = useState<Contact>(
    {} as Contact,
  );
  const [openDeleteConfirmationModel, setOpenDeleteConfirmationModel] =
    useState<boolean>(false);
  const [searchTextForAccountDropdown, setSearchTextForAccountDropdown] =
    useState<string>('');
  const [searchTextForContactDropdown, setSearchTextForContactDropdown] =
    useState<string>('');

  const [indexToDelete, setIndexToDelete] = useState<number>(
    null as unknown as number,
  );
  const [pairToDelete, setPairToDelete] = useState<PairToDelete>(
    null as unknown as PairToDelete,
  );

  const [
    arrayOfAccountAndContactDataType,
    setArrayOfAccountAndContactDataType,
  ] = useState<AccountAndContactDataTypeObj[]>([]);
  const [
    arrayOfOpportunityAndContactDataType,
    setArrayOfOpportunityAndContactDataType,
  ] = useState<OpportunityAndContactDataTypeObj[]>([]);
  const [
    arrayOfAccountAndContactDataTypeInitially,
    setArrayOfAccountAndContactDataTypeInitially,
  ] = useState<OpportunityAndContactDataTypeObj[]>(
    [] as OpportunityAndContactDataTypeObj[],
  );

  const [
    arrayOfOpportunityAndContactDataTypeInitially,
    setArrayOfOpportunityAndContactDataTypeInitially,
  ] = useState<AccountAndContactDataTypeObj[]>(
    [] as AccountAndContactDataTypeObj[],
  );

  const [selectedContactGroups, setSelectedContactGroups] = useState<ContactGroups>([]);
  const [contactGroups, setContactGroups] = useState<ContactGroups>([]);
  const [usersForUserCfDataType, setUsersForUserCfDataType] = useState([]);
  const [searchTextForUsers, setSearchTextForUsers] = useState('');
  const [searchTextForUsersDebouncedValue, setSearchTextForUsersDebouncedValue] = useState('');

  useEffect(() => {
    setCustomFieldsObj(settingCustomFieldObjWithUndeletedCustomFields());
    fetchAccountsCustomField();
    fetchOpportunityCustomField();
    if (contactId) {
      fetchContactById(contactId);
    }
    return () => {
      dispatch(Actions.setIsSavingAsDraft(false));
    };
  }, []);

  useEffect(() => {
    if (fetchAllOpportunityStateSuccess) {
      setDynamicStates(opportunityDynamicStates);
      if (contactToBeEdited && Object.keys(contactToBeEdited).length && Array.isArray(contactToBeEdited.state) && contactToBeEdited.state.length > 0 && contactToBeEdited.state[0].name !== "Draft") {
        setSelectedDynamicState(JSON.stringify(contactToBeEdited.state[0]));
      }else{
        let defaultState = opportunityDynamicStates.find(
          (state) => state.isDefault == true,
        );
        
        if (defaultState && defaultState._id) {
          setSelectedDynamicState(JSON.stringify({_id:defaultState._id,name:defaultState.name}));
        }
      }
    }
  }, [fetchAllOpportunityStateSuccess, opportunityDynamicStates, contactToBeEdited]);
  
  useEffect(() => {
    if (initialRender1.current) {
      initialRender1.current = false;
    } else {
      if (updateOpportunityContactSuccess) {
        updateContactDataTypeCustomFieldInAccounts(contactToBeEdited);
        updateContactDataTypeCustomFieldInOpportunity(contactToBeEdited);
        setShowAddNewModal(false);
        resetAddNewModal();
        setShowAddNewfromGroupTree(false);
      }
    }
  }, [updateOpportunityContactSuccess]);

  useEffect(() => {
    if (initialRender2.current) {
      initialRender2.current = false;
    } else {
      if (createOpportunityContactSuccess) {
        updateContactDataTypeCustomFieldInAccounts(openedContact);
        updateContactDataTypeCustomFieldInOpportunity(openedContact);
        // updateContactDataTypeCustomField(openedContact._id);
        setShowAddNewModal(false);
        resetAddNewModal();
        setShowAddNewfromGroupTree(false);
      }
    }
  }, [createOpportunityContactSuccess]);

  useEffect(() => {
    let timeout: any;
    if (initialRender3.current) {
      initialRender3.current = false;
    } else {
      if (searchTextForAccountDropdown.length > 2) {
        timeout = setTimeout(() => {
          searchContactOrOpportunity("account");
        }, 1);
      }
      if (timeout !== undefined) {
        return () => clearTimeout(timeout);
      }
    }
  }, [searchTextForAccountDropdown]);

  const fetchAllTheActiveUsers = async () => {
    try {
      const data = {
        searchText: searchTextForUsers
      };
      const response: any = await getActiveUsersForCompanyAPI(data);
      if (response.data.success === true) {
        setUsersForUserCfDataType(response.data.users);
      } else {
        setUsersForUserCfDataType([]);
        createNotification('error', 'Something went wrong');
      }
    } catch (error) {
      setUsersForUserCfDataType([]);
      console.log('error', error);
    }
  };

  useEffect(() => {
    fetchAllTheActiveUsers();
  }, [searchTextForUsersDebouncedValue]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setSearchTextForUsersDebouncedValue(searchTextForUsers);
    }, 500);
    return () => { clearTimeout(timerId); };
  }, [searchTextForUsers]);

  const searchContactOrOpportunity = async (forType: string) => {
    let from = contactId ? 'editContact' : 'createContact'; // Simplified conditional logic
  
    const opportunityContactsAPI = new OpportunityContactsAPI();
  
    try {
      const res = await opportunityContactsAPI.searchContactOrOpportunity({
        searchText: forType === "account" ? searchTextForAccountDropdown:searchTextForContactDropdown,
        for: forType,
      });
  
      if (forType === "account") {
        setAccountSearchList(res.data?.accounts || []);
      }else{
        setOpportunitySearchList(res.data?.opportunites || []);
      }
    } catch (error) {
      console.error("Error searching for contacts or opportunities:", error);
    }
  };

  useEffect(() => {
    let timeout: any;
    if (initialRender4.current) {
      initialRender4.current = false;
    } else {
      if (searchTextForContactDropdown.length > 2) {
        timeout = setTimeout(() => {
          searchContactOrOpportunity("opportunity");
        }, 1);
      }
      if (timeout !== undefined) {
        return () => clearTimeout(timeout);
      }
    }
  }, [searchTextForContactDropdown]);

  useEffect(() => {
    let groupsModified = [] as ContactGroups;
    if (Array.isArray(groups) && groups.length) {
      groupsModified = groups
        .filter(group => group.isSmartGroup === false) // Filter groups where isSmartGroup is false
        .map(group => ({ value: group._id, label: group.audienceName }));
    }
    setContactGroups(groupsModified);
  }, [groups]);


  // const fetchAllOpportunities = async () => {
  //   let from = 'createContact';
  //   if (contactId) {
  //     from = 'editContact';
  //   }
  //   const data = {
  //     queryParams: {
  //       pageNo: 1,
  //       searchOnlyName: true,
  //       searchText: searchTextForContactDropdown,
  //       from: from,
  //     },
  //     bodyParams: { filterArray: [] },
  //   };
  //   const opportunityOpportunitiesAPI = new OpportunityOpportunitiesAPI();
  //   const res =
  //     await opportunityOpportunitiesAPI.fetchAllOpportunityOpportunities(data);
  //   setOpportunitySearchList(res.data.opportunities);
  // };

  const fetchContactById = async (contactId: string) => {
    const opportunityContactsAPI = new OpportunityContactsAPI();
    const res = await opportunityContactsAPI.fetchOpportunityContact({
      id: contactId,
    });

    setContactToBeEdited(res.data.contact);
    let accountAndContactDataType =
      res.data.contact?.accountAndContactDataType || [];

    setArrayOfAccountAndContactDataType(
      accountAndContactDataType.map((obj: any) => ({
        accountId: obj.account._id,
        contactDataTypeCfId: obj.contactDataTypeCf._id,
      })),
    );

    setArrayOfAccountAndContactDataTypeInitially(
      accountAndContactDataType.map((obj: any) => ({
        accountId: obj.account._id,
        contactDataTypeCfId: obj.contactDataTypeCf._id,
      })),
    );

    setAccount((prevAccount) => {
      const accountCopy = [...prevAccount];
      accountAndContactDataType.forEach((obj: any, i: number) => {
        accountCopy[i] = obj.account;
      });
      return accountCopy;
    });

    setAccountContactCF((prevAccountContactCF) => {
      const accountContactCFCopy = [...prevAccountContactCF];
      accountAndContactDataType.forEach((obj: any, i: number) => {
        accountContactCFCopy[i] = obj.contactDataTypeCf;
      });
      return accountContactCFCopy;
    });

    let opportunityAndContactDataType =
      res.data.contact?.opportunityAndContactDataType || [];

    setArrayOfOpportunityAndContactDataType(
      opportunityAndContactDataType.map((obj: any) => ({
        opportunityId: obj.opportunity._id,
        contactDataTypeCfId: obj.contactDataTypeCf._id,
      })),
    );

    setArrayOfOpportunityAndContactDataTypeInitially(
      opportunityAndContactDataType.map((obj: any) => ({
        opportunityId: obj.opportunity._id,
        contactDataTypeCfId: obj.contactDataTypeCf._id,
      })),
    );

    setOpportunity((prevOpp) => {
      const opportunityCopy = [...prevOpp];
      opportunityAndContactDataType.forEach((obj: any, i: number) => {
        opportunityCopy[i] = obj.opportunity;
      });
      return opportunityCopy;
    });

    setOpportunityContactCF((prevOppContactCF) => {
      const oppContactCFCopy = [...prevOppContactCF];
      opportunityAndContactDataType.forEach((obj: any, i: number) => {
        oppContactCFCopy[i] = obj.contactDataTypeCf;
      });
      return oppContactCFCopy;
    });
  };

  const hasDuplicates = (
    array: AccountAndContactDataTypeObj[] | OpportunityAndContactDataTypeObj[],
  ) => {
    const seen = new Set();
    for (const item of array) {
      const stringifiedItem = JSON.stringify(item);
      if (seen.has(stringifiedItem)) {
        return true;
      }
      seen.add(stringifiedItem);
    }
    return false;
  };

  const handleDynamicStateChange = (e: SelectChangeEvent<String>) => {
    setSelectedDynamicState(e.target.value as string);
  };

  const isValidArrayOfAccountAndContactDataType = () => {
    let isValid = true;
    if (arrayOfAccountAndContactDataType.length > 0) {
      let errorsArr = [{}];
      arrayOfAccountAndContactDataType.forEach((obj, i) => {
        if (!obj.accountId) {
          errorsArr[i] = { ...errorsArr[i], accountIdError: true };
          isValid = false;
        } else {
          errorsArr[i] = { ...errorsArr[i], accountIdError: false };
        }
        if (!obj.contactDataTypeCfId) {
          errorsArr[i] = { ...errorsArr[i], contactDataTypeCfIdError: true };
          isValid = false;
        } else {
          errorsArr[i] = { ...errorsArr[i], contactDataTypeCfIdError: false };
        }
      });
      setAccountAndDataErrorsArr(errorsArr);
    }

    if (isValid) {
      if (hasDuplicates(arrayOfAccountAndContactDataType)) {
        createNotification('error', 'Duplicate accounts are linked');
        isValid = false;
      }
    }
    return isValid;
  };
  const isValidArrayOfOpportunityAndContactDataType = () => {
    let isValid = true;
    if (arrayOfOpportunityAndContactDataType.length > 0) {
      let errorsArr = [{}];
      arrayOfOpportunityAndContactDataType.forEach((obj, i) => {
        if (!obj.opportunityId) {
          errorsArr[i] = { ...errorsArr[i], opportunityIdError: true };
          isValid = false;
        } else {
          errorsArr[i] = { ...errorsArr[i], opportunityIdError: false };
        }
        if (!obj.contactDataTypeCfId) {
          errorsArr[i] = { ...errorsArr[i], contactDataTypeCfIdError: true };
          isValid = false;
        } else {
          errorsArr[i] = { ...errorsArr[i], contactDataTypeCfIdError: false };
        }
      });
      setOpportunityAndDataErrorsArr(errorsArr);
    }
    if (isValid) {
      if (hasDuplicates(arrayOfOpportunityAndContactDataType)) {
        createNotification('error', 'Duplicate opportunities are linked');
        isValid = false;
      }
    }
    return isValid;
  };

  const fetchAccountsCustomField = async () => {
    let from = 'createContact';
    if (contactId) {
      from = 'editContact';
    }
    try {
      const opportunityAccountsCustomFieldsAPI =
        new OpportunityAccountsCustomFieldsAPI();
      const data = {
        customFieldType: 'accounts',
        from: from,
      };
      const res =
        await opportunityAccountsCustomFieldsAPI.fetchAllOpportunityCustomField(
          data,
        );
      setAccountsCustomFieldsWithOnlyContactDataType(
        (res.data.customFields as CustomFieldsMain)
          .filter((cf: CustomField) => cf.fieldData.fieldType === 'contact')
          .map((obj) => ({
            _id: obj._id,
            fieldName: obj.fieldData.fieldName,
          })) as any,
      );
    } catch (error) {
      console.log(error);
    }
  };

  const fetchOpportunityCustomField = async () => {
    try {
      let from = 'createContact';
      if (contactId) {
        from = 'editContact';
      }
      const opportunityAccountsCustomFieldsAPI =
        new OpportunityAccountsCustomFieldsAPI();
      const data = {
        customFieldType: 'opportunity',
        from: from,
      };
      const res =
        await opportunityAccountsCustomFieldsAPI.fetchAllOpportunityCustomField(
          data,
        );

      // @ts-ignore
      setOpportunityCustomFieldsWithOnlyContactDataType(
        (res.data.customFields as CustomFieldsMain)
          .filter((cf: CustomField) => cf.fieldData.fieldType === 'contact')
          .map((obj) => ({
            _id: obj._id,
            fieldName: obj.fieldData.fieldName,
          })),
      );
    } catch (error) {
      console.log(error);
    }
  };

  const settingCustomFieldObjWithUndeletedCustomFields = () => {
    const obj: CustomFieldsObj = {};
    customFields.forEach((cf) => {
      if (cf.fieldData.fieldType === 'boolean') {
        return (obj[cf._id as string] = '');
      } else {
        return (obj[cf._id as string] = '');
      }
    });
    return obj;
  };

  const settingCustomFieldObjWithExistingCustomFieldsForAGivenContact = (
    existingCFInContact: CustomFieldsObj,
  ) => {
    let commonKeysObj: CustomFieldsObj = {};
    customFields.forEach((cf) => {
      if (Object.keys(existingCFInContact).includes(cf._id as string)) {
        return (commonKeysObj[cf._id as string] =
          existingCFInContact[cf._id as string]);
      } else {
        if (cf.fieldData.fieldType === 'boolean') {
          commonKeysObj[cf._id as string] = '';
        } else {
          commonKeysObj[cf._id as string] = '';
        }
      }
    });
    setCustomFieldsObj(commonKeysObj);
  };

  useEffect(() => {
    if (contactToBeEdited) {
      if (Object.keys(contactToBeEdited).length) {
        
        if (contactToBeEdited.firstName) {
          setFirstName(contactToBeEdited.firstName);
        }
        if (contactToBeEdited.middleName) {
          setMiddleName(contactToBeEdited.middleName);
        }

        if (contactToBeEdited.lastName) {
          setLastName(contactToBeEdited.lastName);
        }
        if (contactToBeEdited.email) {
          setEmail(contactToBeEdited.email);
        }

        if (contactToBeEdited.source) {
          setSource(contactToBeEdited.source);
        }
        if (contactToBeEdited.audience) {
          let { audience } = contactToBeEdited;
          let selectedGroup = groups.filter(group => audience.map(obj => obj._id).includes(group._id));
          let formattedGroups = selectedGroup.map(group => ({ label: group.audienceName, value: group._id }));
          setSelectedContactGroups(formattedGroups);
        }

        if (contactToBeEdited.customFields) {
          settingCustomFieldObjWithExistingCustomFieldsForAGivenContact(
            contactToBeEdited.customFields as CustomFieldsObj,
          );
        }
        setIdBeingEdited(contactToBeEdited._id);
      }
    }

    if (groupClickObject.groupClick && groupClickObject.groupId) {
      let selectedGroup = groups.filter(
        group => groupClickObject.groupId === group._id && group.isSmartGroup === false
      );
      let formattedGroups = selectedGroup.map(group => ({
        label: group.audienceName,
        value: group._id,
      }));
      setSelectedContactGroups(formattedGroups);
    }

    if (Array.isArray(customFields) && customFields.length) {
      customFields.filter((cf) => cf.fieldData.fieldType === 'contact');
    }
  }, [contactToBeEdited, customFields]);
  useEffect(() => {
    if (opportunityState.isSavingAsDraft) {
      handleAddOrUpdate();
    }
  }, [opportunityState.isSavingAsDraft]);

  const handleClose = () => {
    setShowAddNewModal(false);
    setShowAddNewfromGroupTree(false);
    resetAddNewModal();
  };

  const isValid = () => {
    let isValid = true;
    let errorsObj: CustomFieldsValidationError = {};
    try {
      if (!firstName) {
        errorsObj.firstName = 'Please enter the first Name';
      }
      if (!opportunityState.isSavingAsDraft) {
        const nonDraftStates = dynamicStates.filter(state => state.stateType !== 'Draft');

        if (nonDraftStates.length === 0) {
          errorsObj.dynamicState = 'Please add states under Settings before proceeding';
        } else if (!selectedDynamicState || selectedDynamicState.trim() === '') {
          errorsObj.dynamicState = 'Please select the state';
        } else {
          delete errorsObj.dynamicState;
        }

        if (email && !isValidEmail(email)) {
          errorsObj.email = 'Please enter a valid email';
        }
        customFields.forEach((cf: CustomField) => {
          for (const key in customFieldsObj) {
            if (key === cf._id) {
              if (cf.isMandatory) {
                if (
                  Array.isArray(customFieldsObj[key]) &&
                  !customFieldsObj[key].length
                ) {
                  errorsObj[key] = true;
                }
                if (cf.fieldData.fieldType === 'date') {
                  if ((customFieldsObj?.[cf._id as string] &&
                    moment(
                      customFieldsObj?.[cf._id as string] as string,
                    ).format('YYYY-MM-DD') === 'Invalid date' ||
                    !customFieldsObj?.[cf._id as string])) {
                    errorsObj[key] = true;
                  }
                }
                if (!customFieldsObj[key]) {
                  errorsObj[key] = true;
                }
              }
              if (cf.fieldData.fieldType === 'email') {
                if (
                  customFieldsObj[key] &&
                  !isValidEmail(customFieldsObj[key] as string)
                ) {
                  errorsObj[key] = true;
                }
              }
              if (cf.fieldData.fieldType === 'url') {
                if (
                  customFieldsObj[key] &&
                  !isValidURL(customFieldsObj[key] as string)
                ) {
                  errorsObj[key] = true;
                }
              }
              if (cf.fieldData.fieldType === 'facebook' || cf.fieldData.fieldType === 'linkedin' ||
                cf.fieldData.fieldType === 'x' || cf.fieldData.fieldType === 'pinterest' ||
                cf.fieldData.fieldType === 'instagram'
              ) {
                if (
                  customFieldsObj[key] &&
                  !validateSocialMediaUrl(customFieldsObj[key] as string, cf.fieldData.fieldType)
                ) {
                  errorsObj[key] = true;
                }
              }
              if (cf.fieldData.fieldType === 'number') {
                const numbersAndHyphensRegex = /^[0-9-]*$/;
                if (
                  !numbersAndHyphensRegex.test(customFieldsObj[key] as string)
                ) {
                  errorsObj[key] = true;
                }
              }
              if (cf.fieldData.fieldType === 'date') {
                if (customFieldsObj?.[cf._id as string] &&
                  moment(
                    customFieldsObj?.[cf._id as string] as string,
                  ).format('YYYY-MM-DD') === 'Invalid date') {
                  errorsObj[key] = true;
                }
              }
            }
          }
        });
      }
      setErrors(errorsObj);
    } catch (error) {
      console.log(error);
    }

    if (Object.keys(errorsObj).length) {
      setCustomFieldsValidationError(errorsObj);
      isValid = false;
    } else {
      setCustomFieldsValidationError({});
    }
    !isValid && opportunityState.isSavingAsDraft && setIsSavingAsDraftAsFalse();

    return isValid;
  };

  const setIsSavingAsDraftAsFalse = () => {
    dispatch(Actions.setIsSavingAsDraft(false));
  };

  const handleAddOrUpdate = () => {
    let isValidValue = isValid();
    let isValidArrayOfAccountAndContactDataTypeValue = isValidArrayOfAccountAndContactDataType();
    let isValidArrayOfOpportunityAndContactDataTypeValue = isValidArrayOfOpportunityAndContactDataType();
    opportunityState.isSavingAsDraft && setIsSavingAsDraftAsFalse();

    if (
      isValidValue &&
      isValidArrayOfAccountAndContactDataTypeValue &&
      isValidArrayOfOpportunityAndContactDataTypeValue
    ) {
      const bodyParams: BodyParams = {
        groupId: (groupClickObject.groupClick && groupClickObject.groupId && groupClickObject.isSmartGroup) ? groupClickObject.groupId : '',
        isSmartGroup: (groupClickObject.groupClick && groupClickObject.groupId && groupClickObject.isSmartGroup) ? groupClickObject.isSmartGroup : false,
        customFields: customFieldsObj,
        firstName: firstName.trim(),
        middleName: middleName.trim(),
        lastName: lastName.trim(),
        email: email.trim().toLowerCase(),
        source: source,
        arrayOfAccountAndContactDataType,
        status: "new",
        audienceIds: selectedContactGroups.map(el => el.value),
        groupsDetail: selectedContactGroups.map(el => ({ _id: el.value, audienceName: el.label })),
        filterArray: undefined
      };

      const isEmpty = isEmptyObject(bodyParams as any);

      if (isEmpty) {
        createNotification('error', 'At least one value is required');
        return;
      }

      if (idBeingEdited) {
        bodyParams._id = idBeingEdited;
      }
      if(opportunityState.isSavingAsDraft){
        setIsSavingAsDraftAsFalse()
        bodyParams.status = "draft" 
      } 

      if (selectedDynamicState || opportunityState.isSavingAsDraft) {
        let draftState = dynamicStates.find(
          (state) => state.stateType === 'Draft',
        );
        bodyParams['state'] = opportunityState.isSavingAsDraft
          ? {_id:draftState?._id, name:draftState?.name}
          : JSON.parse(selectedDynamicState);
      }
      let data;
      if (groupClickObject.groupClick && groupClickObject.groupId) {
        data = {
          bodyParams: {
            ...bodyParams,
            filterArray: {
              filterByAudience: [groupClickObject.groupId],
              filterBySource:bodyParamsForfiltering.filterBySource,
              filterByCustomField:bodyParamsForfiltering.filterByCustomField,
              filterByStatus:bodyParamsForfiltering.filterByStatus,
              filterByLastImported:bodyParamsForfiltering.filterByLastImported,
              filterByState:bodyParamsForfiltering.filterByState,
            },
          },
          queryParams: queryParams,
        };
      } else {
         data = {
          bodyParams: {
            ...bodyParams, // Spread the existing bodyParams
            ...bodyParamsForfiltering, // Spread bodyParamsForfiltering to merge into bodyParams
          },
          queryParams: queryParams,
        }
        data = {
          bodyParams: {
            ...bodyParams,
            filterArray: {
              filterByAudience: bodyParamsForfiltering.filterByAudience,
              filterBySource:bodyParamsForfiltering.filterBySource,
              filterByCustomField:bodyParamsForfiltering.filterByCustomField,
              filterByStatus:bodyParamsForfiltering.filterByStatus,
              filterByLastImported:bodyParamsForfiltering.filterByLastImported,
              filterByState:bodyParamsForfiltering.filterByState,
            },
          },
          queryParams: queryParams,
        };
      }
      idBeingEdited
        ? dispatch(Actions.updateOpportunityContactRequest(data))
        : dispatch(Actions.createOpportunityContactRequest(data));
    }
  };
  const updateContactDataTypeCustomFieldInAccounts = (contact: string) => {
    const data = {
      arrayOfAccountAndContactDataType: arrayOfAccountAndContactDataType,
      arrayOfAccountAndContactDataTypeInitially:
        arrayOfAccountAndContactDataTypeInitially,
      contactId: contact,
    };
    const opportunityAccountsAPI = new OpportunityAccountsAPI();
    opportunityAccountsAPI.updateContactDataTypeCustomField(data);
  };

  const updateContactDataTypeCustomFieldInOpportunity = (contact: string) => {
    const data = {
      arrayOfOpportunityAndContactDataType:
        arrayOfOpportunityAndContactDataType,
      arrayOfOpportunityAndContactDataTypeInitially:
        arrayOfOpportunityAndContactDataTypeInitially,
      contactId: contact,
    };
    const opportunityOpportunitiesAPI = new OpportunityOpportunitiesAPI();
    opportunityOpportunitiesAPI.updateContactDataTypeCustomField(data);
  };

  function isEmptyObject(body: BodyToCheckEmptyObject) {
    const obj = { ...body };
    delete obj.source;
    // Check if obj is not an object or is null
    if (typeof obj !== 'object' || obj === null) {
      return false;
    }

    // Check if obj has any own properties
    if (Object.keys(obj).length === 0) {
      return true;
    }

    // Iterate over each property of obj
    for (let key in obj) {
      // If the property value is an object, recursively call isEmptyObject
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        if (!isEmptyObject(obj[key] as BodyToCheckEmptyObject)) {
          return false;
        }
      } else {
        // If the property value is not an object and not empty, return false
        if (obj[key] !== '' && obj[key] !== null && obj[key] !== undefined) {
          return false;
        }
      }
    }
    // If all properties are empty, return true
    return true;
  }

  const handleCustomFieldsObj = (
    e: any,
    id: string,
    fieldType?: string,
    isMultiSelect?: boolean,
  ) => {
    const setingCFObj = (value: any,) => {
      let obj = {
        ...customFieldsObj,
      };
      if (fieldType === 'user') {
        let prevArr = (obj[id as string] as string[]);
        if (!prevArr) {
          prevArr = [];
        }
        if (prevArr.some((selected) => selected._id === value._id)) {
          prevArr = prevArr.filter(el => el._id !== value._id);
          obj[id as string] = [...prevArr];
        } else {
          obj[id as string] = [...prevArr, value];
        }
      } else {
        obj[id as string] = value;
      }
      setCustomFieldsObj(obj);
    };
    if (fieldType === 'number') {
      const regex = /^[0-9-]*$/;
      if (regex.test(e.target.value)) {
        setingCFObj(e.target.value);
      }
    } else if (fieldType === 'list' && !isMultiSelect) {
      setingCFObj([e.target.value]);
    } else if (fieldType === 'phone') {
      setingCFObj(e);
    } else if (fieldType === 'user') {
      setingCFObj(e);
    } else {
      setingCFObj(e.target.value);
    }
  };

  const handleCheckboxes = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string,
    value: string,
  ) => {
    let obj = {
      ...customFieldsObj,
    };
    let checkboxArr = obj[id] as string[];
    if (e.target.checked) {
      obj[id] = [...checkboxArr, value];
    } else {
      let arr = Array.isArray(checkboxArr)
        ? checkboxArr.filter((v) => value !== v)
        : [];
      obj[id] = arr;
    }
    setCustomFieldsObj(obj);
  };

  const handleSearchListInSearchableDropdownForAccounts = (text: any) => {
    if (text) {
      setSearchTextForAccountDropdown(text);
    } else {
      setAccountSearchList(null);
    }
  };

  const handleSearchListInSearchableDropdownForOpportunities = (text: any) => {
    if (text) {
      setSearchTextForContactDropdown(text);
    } else {
      setOpportunitySearchList(null);
    }
  };

  const handleAccountContactCFSearchListInSearchableDropdownForAccounts = (
    text: any,
  ) => {
    if (text) {
      const regex = new RegExp('^' + text, 'i');
      let list = accountsCustomFieldsWithOnlyContactDataType.filter(
        //@ts-ignore
        (cf: CustomField) => regex.test(cf.fieldName),
      );
      setAccountContactCFSearchList(list);
    } else {
      setAccountContactCFSearchList(null);
    }
  };

  const handleOpportunityContactCFSearchListInSearchableDropdownForOpportunity =
    (text: any) => {
      if (text) {
        const regex = new RegExp('^' + text, 'i');
        let list = opportunityCustomFieldsWithOnlyContactDataType.filter(
          //@ts-ignore
          (cf: CustomField) => regex.test(cf.fieldName),
        );
        setOpportunityContactCFSearchList(list);
      } else {
        setOpportunityContactCFSearchList(null);
      }
    };

  const handleAccountChangeForSearchableDropdown = (option: any, i: number) => {
    let arr = [...account];
    arr[i] = option;
    setAccount(arr);

    const arrayOfAccountAndContactDataTypeCopy = [
      ...arrayOfAccountAndContactDataType,
    ];

    arrayOfAccountAndContactDataTypeCopy[i].accountId = option._id;
    setArrayOfAccountAndContactDataType(arrayOfAccountAndContactDataTypeCopy);
    setAccountSearchList(null);
  };

  const handleOpportunityChangeForSearchableDropdown = (
    option: any,
    i: number,
  ) => {
    let arr = [...opportunity];
    arr[i] = option;
    setOpportunity(arr);

    const arrayOfOpportunityAndContactDataTypeCopy = [
      ...arrayOfOpportunityAndContactDataType,
    ];

    arrayOfOpportunityAndContactDataType[i].opportunityId = option._id;
    setArrayOfOpportunityAndContactDataType(
      arrayOfOpportunityAndContactDataTypeCopy,
    );
    setOpportunitySearchList(null);
  };

  const handleAccountContactCFChangeForSearchableDropdown = (
    option: any,
    i: number,
  ) => {
    let arr = [...accountContactCF];
    arr[i] = option;
    setAccountContactCF(arr);

    const arrayOfAccountAndContactDataTypeCopy = [
      ...arrayOfAccountAndContactDataType,
    ];
    arrayOfAccountAndContactDataTypeCopy[i].contactDataTypeCfId = option._id;
    setArrayOfAccountAndContactDataType(arrayOfAccountAndContactDataTypeCopy);
  };

  const handleOpportunityContactCFChangeForSearchableDropdown = (
    option: any,
    i: number,
  ) => {
    let arr = [...opportunityContactCF];
    arr[i] = option;
    setOpportunityContactCF(arr);

    const arrayOfOpportunityAndContactDataTypeCopy = [
      ...arrayOfOpportunityAndContactDataType,
    ];
    arrayOfOpportunityAndContactDataTypeCopy[i].contactDataTypeCfId =
      option._id;
    setArrayOfOpportunityAndContactDataType(
      arrayOfOpportunityAndContactDataTypeCopy,
    );
  };

  const errorStatement = (cf: CustomField) => {
    if (customFieldsValidationError?.[cf._id as string]) {
      if (cf.fieldData.fieldType === 'email') {
        return 'Please enter a valid email';
      }
      if (cf.fieldData.fieldType === 'url') {
        return 'Please enter a valid URL';
      }
      if (cf.fieldData.fieldType === 'x') {
        return 'Please enter a valid URL';
      }
      if (cf.fieldData.fieldType === 'instagram') {
        return 'Please enter a valid URL';
      }
      if (cf.fieldData.fieldType === 'facebook') {
        return 'Please enter a valid URL';
      }
      if (cf.fieldData.fieldType === 'pinterest') {
        return 'Please enter a valid URL';
      }
      if (cf.fieldData.fieldType === 'linkedin') {
        return 'Please enter a valid URL';
      }
      if (cf.fieldData.fieldType === 'number') {
        return 'Please enter a valid number';
      }
      if (cf.fieldData.fieldType === 'date') {
        return 'Please enter a valid date';
      }
      if (cf.fieldData.fieldType === 'boolean') {
        return 'Please choose the value';
      }
      if (cf.fieldData.fieldType === 'list') {
        return 'Please select a value';
      } else {
        return 'Please enter a value';
      }
    } else return '';
  };

  const handleChangeMultiselectForContactGroup = (selected: ContactGroups) => {
    setSelectedContactGroups(selected);
  };

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
  };
  const handleMiddleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMiddleName(e.target.value);
  };
  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  };
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const addAccountAndContactDataType = () => {
    setArrayOfAccountAndContactDataType((prev) => [
      ...prev,
      {
        accountId: '',
        contactDataTypeCfId: '',
      },
    ]);

    const accountContactCFCopy = [...accountContactCF, {}];
    setAccountContactCF(accountContactCFCopy);

    const accountCopy = [...account, {}];
    setAccount(accountCopy);
  };

  const addOpportunityAndContactDataType = () => {
    setArrayOfOpportunityAndContactDataType((prev) => [
      ...prev,
      {
        opportunityId: '',
        contactDataTypeCfId: '',
      },
    ]);

    const opportunityContactCFCopy = [...opportunityContactCF, {}];
    setOpportunityContactCF(opportunityContactCFCopy);

    const opportunityCopy = [...opportunity, {}];
    setOpportunity(opportunityCopy);
  };

  const removeAccountAndContactDataType = () => {
    let i = indexToDelete;
    const arrayOfAccountAndContactDataTypeCopy = [
      ...arrayOfAccountAndContactDataType,
    ];

    arrayOfAccountAndContactDataTypeCopy.splice(i, 1);
    setArrayOfAccountAndContactDataType(arrayOfAccountAndContactDataTypeCopy);

    const accountContactCFCopy = [...accountContactCF];
    accountContactCFCopy.splice(i, 1);
    setAccountContactCF(accountContactCFCopy);

    const accountCopy = [...account];
    accountCopy.splice(i, 1);
    setAccount(accountCopy);
    closeDeleteConfirmationModel();
    setAccountAndDataErrorsArr([]);
  };

  const removeOpportunityAndContactDataType = () => {
    let i = indexToDelete;
    const arrayOfOpportunityAndContactDataTypeCopy = [
      ...arrayOfOpportunityAndContactDataType,
    ];

    arrayOfOpportunityAndContactDataTypeCopy.splice(i, 1);
    setArrayOfOpportunityAndContactDataType(
      arrayOfOpportunityAndContactDataTypeCopy,
    );

    const opportunityContactCFCopy = [...opportunityContactCF];
    opportunityContactCFCopy.splice(i, 1);
    setOpportunityContactCF(opportunityContactCFCopy);

    const opportunityCopy = [...opportunity];
    opportunityCopy.splice(i, 1);
    setOpportunity(opportunityCopy);
    closeDeleteConfirmationModel();
    setOpportunityAndDataErrorsArr([]);
  };

  const closeDeleteConfirmationModel = () => {
    setOpenDeleteConfirmationModel(false);
  };

  const handleOpenDeleteConfirmationModel = (
    index: number,
    type: PairToDelete,
  ) => {
    setPairToDelete(type);
    setIndexToDelete(index);
    setOpenDeleteConfirmationModel(true);
  };

  const handleClearAllUsers = (checked: boolean, cfId: string) => {
    let obj = { ...customFieldsObj };
    if (checked) {
      obj[cfId] = [];
      setCustomFieldsObj({ ...obj });
    }
  };

  return (
    <Box className="opportunity-tab">
      <Grid container spacing={2} pb={3} className="top-filter-area" sx={{position:'sticky', top:0, zIndex:1, bgcolor:'#f6f6fc'}}>
        <Grid item>
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton size="small" onClick={handleClose}>
              <KeyboardBackspaceIcon />
            </IconButton>
            <Box>Back</Box>
          </Stack>
        </Grid>
        <Grid item>
          <Grid container spacing={1.25} className="team-dash-right">
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                disableElevation
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => dispatch(Actions.setIsSavingAsDraft(true))}
                variant="outlined"
                color="primary"
                disableElevation
              >
                Save As Draft
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  opportunityState.isSavingAsDraft && setIsSavingAsDraftAsFalse();
                  handleAddOrUpdate();
                }}
                variant="contained"
                color="primary"
                disableElevation
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent={'center'}>
        <Grid item xs={12} md={10} xl={10}>
          <Box px={3} pt={2} pb={4}>
            <Box
              component={Grid}
              //   @ts-ignore
              rowSpacing={2}
              columnSpacing={2}
              container
              className="inputField"
            >
              <Grid item xs={12} sm={6}>
                <label>
                  First Name
                  <span className="required">*</span>
                </label>
                <input
                  type="text"
                  className="form-control small"
                  placeholder="Please enter the first name"
                  onChange={handleFirstNameChange}
                  value={firstName}
                />
                <span className="error_mesage required">
                  {errors.firstName}
                </span>
              </Grid>
              <Grid item xs={12} sm={6}>
                <label>Middle Name</label>
                <input
                  type="text"
                  className="form-control small"
                  placeholder="Please enter the middle name"
                  onChange={handleMiddleNameChange}
                  value={middleName}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <label>Last Name</label>
                <input
                  type="text"
                  className="form-control small"
                  placeholder="Please enter the last name"
                  onChange={handleLastNameChange}
                  value={lastName}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <label>Email</label>
                <input
                  type="email"
                  className="form-control small"
                  placeholder="Please enter the email"
                  onChange={handleEmailChange}
                  value={email}
                />
                <span className="error_mesage required">{errors.email}</span>
              </Grid>
              <StateDropdown
                label="State"
                name="dynamicState"
                dynamicStates={dynamicStates}
                selectedState={selectedDynamicState}
                handleStateChange={handleDynamicStateChange}
                errors={errors}
              />

              <Grid item xs={12} sm={6}>
                <label>Contact Group</label>
                <ReactSelect
                  styles={customStyles}
                  className="custom-multi-select"
                  //@ts-ignore
                  options={contactGroups}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{
                    Option,
                  }}
                  //@ts-ignore
                  onChange={handleChangeMultiselectForContactGroup}
                  // allowSelectAll={true}
                  value={selectedContactGroups}
                />
                {/* <span className="error_mesage required">{errors.email}</span> */}
              </Grid>

              {Array.isArray(customFields) &&
                customFields.map((cf) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={
                        cf.fieldData.fieldType === 'contact' ||
                          cf.fieldData.fieldType === 'account' ||
                          cf.fieldData.fieldType === 'longText'
                          ? 12
                          : 6
                      }
                      key={cf._id}
                    >
                      <Box>
                        <label>
                          {cf.fieldData.fieldName}
                          {cf.isMandatory && (
                            <span className="required">*</span>
                          )}
                        </label>
                      </Box>

                      {cf.fieldData.fieldType === 'list' ? (
                        <DropdownForListDataTypeCF
                          cf={cf}
                          customFieldsObj={customFieldsObj}
                          handleCustomFieldsObj={handleCustomFieldsObj}
                        />
                      ) : cf.fieldData.fieldType === 'boolean' ? (
                        <FormControl fullWidth component="fieldset">
                          <RadioGroup
                            // @ts-ignore
                            aria-label={cf.fieldData.fieldName}
                            name={cf.fieldData.fieldName}
                            row
                            onChange={(e) =>
                              handleCustomFieldsObj(e, cf._id as string)
                            }
                            value={
                              customFieldsObj?.[cf._id as string]?.toString() ||
                              ''
                            }
                          >
                            <FormControlLabel
                              value={'true'}
                              control={<Radio color="primary" />}
                              label="True"
                            />
                            <FormControlLabel
                              value={'false'}
                              control={<Radio color="primary" />}
                              label="False"
                            />
                          </RadioGroup>
                        </FormControl>
                      ) : cf.fieldData.fieldType === 'date' ? (
                        <>
                          {/* <input
                            type="date"
                            className="form-control small"
                            value={
                              customFieldsObj?.[cf._id as string] &&
                              moment(
                                customFieldsObj?.[cf._id as string] as string,
                              ).format('YYYY-MM-DD')
                            }
                            onChange={(e) =>
                              handleCustomFieldsObj(e, cf._id as string)
                            }
                          /> */}
                          <div className="date-picker-with-icon form-control">
                            <ReactDatePicker
                              selected={customFieldsObj?.[cf._id] ? new Date(customFieldsObj[cf._id]) : null}
                              onChange={(date) =>
                                handleCustomFieldsObj(
                                  { target: { value: date ? moment(date).format('YYYY-MM-DD') : null } },
                                  cf._id
                                )
                              }
                              id={`date-picker-${[cf._id]}`}
                              dateFormat="dd/MM/yyyy"
                              placeholderText="DD/MM/YYYY"
                              className="form-control small"
                            />
                            <CalendarTodayIcon fontSize='small' style={{ marginLeft: '8px', cursor: 'pointer' }} onClick={() => document.querySelector(`#date-picker-${[cf._id]}`).focus()} />
                          </div>
                          {customFieldsObj?.[cf._id as string] &&
                            moment(
                              customFieldsObj?.[cf._id as string] as string,
                            ).format('YYYY-MM-DD') === 'Invalid date' && (
                              <>{customFieldsObj?.[cf._id as string]}</>
                            )}
                        </>
                      ) : cf.fieldData.fieldType === 'number' ? (
                        <input
                          type="text"
                          className="form-control small"
                          placeholder={`Please enter the ${cf.fieldData.fieldName}`}
                          onChange={(e) =>
                            handleCustomFieldsObj(
                              e,
                              cf._id as string,
                              cf.fieldData.fieldType,
                            )
                          }
                          value={customFieldsObj?.[cf._id as string] as string}
                        />
                      ) : cf.fieldData.fieldType === 'phone' ? (
                        <InputFieldForPhoneDataTypeCF
                          handleCustomFieldsObj={handleCustomFieldsObj}
                          cf={cf}
                          customFieldsObj={customFieldsObj}
                        />
                      ) : cf.fieldData.fieldType === 'longText' ? (
                        <textarea
                          className="form-control"
                          placeholder={`Please enter the ${cf.fieldData.fieldName}`}
                          onChange={(e) =>
                            handleCustomFieldsObj(e, cf._id as string)
                          }
                          value={customFieldsObj?.[cf._id as string] as string}
                          rows={4}
                        ></textarea>
                      ) : cf.fieldData.fieldType === 'user' ? (
                        <CustomSearchableMultiSelectedDropdownWithLoadMore
                          options={usersForUserCfDataType}
                          handleChange={(option: {
                            _id: string;
                            email: string;
                          }) =>
                            handleCustomFieldsObj(
                              option,
                              cf._id as string,
                              cf.fieldData.fieldType,
                            )
                          }
                          handleClearAll={(checked: boolean) =>
                            handleClearAllUsers(checked, cf._id as string)
                          }
                          handleAllCheckbox={() => { }}
                          handleSearchListInSearchableDropdown={(text: string) => {
                            setSearchTextForUsers(text);
                          }}
                          listOfSelectedIds={
                            customFieldsObj?.[cf._id as string] || []
                          }
                          dropdownFor="users"
                          handlePageChange={() => { }}
                          disabled={false}
                        />
                      ) : (
                        <input
                          type={
                            cf.fieldData.fieldType === 'email'
                              ? 'email'
                              : 'text'
                          }
                          className="form-control small"
                          placeholder={`Please enter the ${cf.fieldData.fieldName}`}
                          onChange={(e) =>
                            handleCustomFieldsObj(e, cf._id as string)
                          }
                          value={customFieldsObj?.[cf._id as string]}
                        />
                      )}
                      <span className="error_mesage required">
                        {errorStatement(cf as CustomField)}
                      </span>
                    </Grid>
                  );
                })}

              <Grid item xs={12} pt={0}>
                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                  style={{ backgroundColor: '#ffffff' }}
                  p={2}
                >
                  <label>Link Accounts</label>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => addAccountAndContactDataType()}
                    startIcon={<AddIcon />}
                  >
                    Link Account
                  </Button>
                </Box>
                {/* --- repeat start-- */}

                {Array.isArray(arrayOfAccountAndContactDataType) &&
                  arrayOfAccountAndContactDataType.length > 0 &&
                  arrayOfAccountAndContactDataType.map((obj, i) => {
                    return (
                      <Box key={i} style={{ backgroundColor: '#ffffff' }} p={2}>
                        <Grid container spacing={2}>
                          <Grid item xs>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6}>
                                <label>Account</label>
                                <CustomSearchableDropdown
                                  options={accountSearchList}
                                  value={account[i] || {}}
                                  handleChange={(option: any) =>
                                    handleAccountChangeForSearchableDropdown(
                                      option,
                                      i,
                                    )
                                  }
                                  handleSearchListInSearchableDropdown={
                                    handleSearchListInSearchableDropdownForAccounts
                                  }
                                />
                                {Array.isArray(accountAndDataErrorsArr) &&
                                  accountAndDataErrorsArr?.length > 0 &&
                                  accountAndDataErrorsArr[i]?.accountIdError ? (
                                  <span className="error_mesage required">
                                    This field cannot be empty
                                  </span>
                                ) : (
                                  ''
                                )}
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <label>Select Account Contact</label>
                                <CustomSearchableDropdown
                                  options={
                                    accountContactCFSearchList ||
                                    accountsCustomFieldsWithOnlyContactDataType
                                  }
                                  value={accountContactCF[i] || {}}
                                  handleChange={(option: any) =>
                                    handleAccountContactCFChangeForSearchableDropdown(
                                      option,
                                      i,
                                    )
                                  }
                                  handleSearchListInSearchableDropdown={
                                    handleAccountContactCFSearchListInSearchableDropdownForAccounts
                                  }
                                />
                                {Array.isArray(accountAndDataErrorsArr) &&
                                  accountAndDataErrorsArr?.length > 0 &&
                                  accountAndDataErrorsArr[i]
                                    ?.contactDataTypeCfIdError ? (
                                  <span className="error_mesage required">
                                    This field cannot be empty
                                  </span>
                                ) : (
                                  ''
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Box mt={5.5}>
                              <img
                                alt="add-icon"
                                className="addicon"
                                src={EarlyAccessSubIcon}
                                onClick={() =>
                                  // removeAccountAndContactDataType(i)
                                  handleOpenDeleteConfirmationModel(
                                    i,
                                    'account',
                                  )
                                }
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    );
                  })}
              </Grid>

              <Grid item xs={12} pt={0}>
                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                  style={{ backgroundColor: '#ffffff' }}
                  p={2}
                >
                  <label>Link Opportunities</label>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => addOpportunityAndContactDataType()}
                    startIcon={<AddIcon />}
                  >
                    Link Opportunity
                  </Button>
                </Box>
                {/* --- repeat start-- */}
{console.log("arrayOfOpportunityAndContactDataType------>",arrayOfOpportunityAndContactDataType)}

{console.log("opportunitySearchList------>",opportunitySearchList)}
                {Array.isArray(arrayOfOpportunityAndContactDataType) &&
                  arrayOfOpportunityAndContactDataType.length > 0 &&
                  arrayOfOpportunityAndContactDataType.map((obj, i) => {
                    return (
                      <Box key={i} style={{ backgroundColor: '#ffffff' }} p={2}>
                        <Grid container spacing={2}>
                          <Grid item xs>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6}>
                                <label>Opportunity</label>
                                <CustomSearchableDropdown
                                  options={opportunitySearchList}
                                  value={opportunity[i] || {}}
                                  handleChange={(option: any) =>
                                    handleOpportunityChangeForSearchableDropdown(
                                      option,
                                      i,
                                    )
                                  }
                                  handleSearchListInSearchableDropdown={
                                    handleSearchListInSearchableDropdownForOpportunities
                                  }
                                />
                                {Array.isArray(opportunityAndDataErrorsArr) &&
                                  opportunityAndDataErrorsArr?.length > 0 &&
                                  opportunityAndDataErrorsArr[i]
                                    ?.opportunityIdError ? (
                                  <span className="error_mesage required">
                                    This field cannot be empty
                                  </span>
                                ) : (
                                  ''
                                )}
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <label>Select Opportunity Contact</label>
                                <CustomSearchableDropdown
                                  options={
                                    opportunityContactCFSearchList ||
                                    opportunityCustomFieldsWithOnlyContactDataType
                                  }
                                  value={opportunityContactCF[i] || {}}
                                  handleChange={(option: any) =>
                                    handleOpportunityContactCFChangeForSearchableDropdown(
                                      option,
                                      i,
                                    )
                                  }
                                  handleSearchListInSearchableDropdown={
                                    handleOpportunityContactCFSearchListInSearchableDropdownForOpportunity
                                  }
                                />
                                {Array.isArray(opportunityAndDataErrorsArr) &&
                                  opportunityAndDataErrorsArr?.length > 0 &&
                                  opportunityAndDataErrorsArr[i]
                                    ?.contactDataTypeCfIdError ? (
                                  <span className="error_mesage required">
                                    This field cannot be empty
                                  </span>
                                ) : (
                                  ''
                                )}
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item>
                            <Box mt={5.5}>
                              <img
                                alt="add-icon"
                                className="addicon"
                                src={EarlyAccessSubIcon}
                                onClick={() =>
                                  // removeOpportunityAndContactDataType(i)
                                  handleOpenDeleteConfirmationModel(
                                    i,
                                    'opportunity',
                                  )
                                }
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    );
                  })}
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <ConfirmationAlertPopUp
        openModal={openDeleteConfirmationModel}
        closeModalFunc={closeDeleteConfirmationModel}
        title={'Delete Confirmation!'}
        text={'Are you sure you want to delete it?'}
        confirmationButtonText={'Delete'}
        confirmationButtonColor="secondary"
        closeButtonText={'Cancel'}
        functionality={() => {
          pairToDelete === 'account'
            ? removeAccountAndContactDataType()
            : removeOpportunityAndContactDataType();
        }}
      />
      {/* {loading && <Loader/>} */}
    </Box>
  );
}

export default AddOrEditContact;
