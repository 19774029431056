import React, { useRef, useState, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../theme/theme';
import Button from '@material-ui/core/Button';
import '../../sass/main.scss';
import DashboardLayout from 'Layouts/DashboardLayout';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link } from 'react-router-dom';
import CalenderIcon from '../../../src/assets/images/icons/meetings.svg';
import MailOutlineIcon from '../../../src/assets/images/mail.svg';
import { createNotification } from 'helpers';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TableFooter, TablePagination, Checkbox, } from '@material-ui/core';
import { Box, Grid, Stack } from '@mui/material';
import MoreIcon from '../../../src/assets/images/icons/more.svg';

import SearchIcon from '../../../src/assets/images/icons/search.svg';
import InfoIcon from '../../assets/images/icons/setting-user-Info.svg';
import DeleteIcon from '../../assets/images/icons/setting-user-delete.svg';
import InviteIcon from '../../assets/images/icons/setting-user-mail.svg';
import StarIcon from '../../assets/images/icons/setting-user-star.svg';
import MailIcon from '../../assets/images/icons/email.svg';
import EditIcon from '../../assets/images/icons/edit.svg';
import SearchIconBar from '../../../src/assets/images/searchIco.png';

import accountMultipleOutline from '../../assets/images/icons/account-multiple-outline.svg';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { styled } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Fade from '@material-ui/core/Fade';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import RoundBadge from '../../Components/CustomBadges/RoundBadge';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@mui/material/FormControl';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { Radio, RadioGroup } from '@material-ui/core';
import downArrow from '../../../src/assets/images/acor-down-arrow.svg';
import { includes } from 'lodash';
import filterIcon from '../../../src/assets/images/icons/filter_alt.svg';
import filterIconWhite from '../../../src/assets/images/icons/filter_alt_white.svg';
import ClearIcon from '@material-ui/icons/Clear';
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import RefreshOutlinedIcon from '@material-ui/icons/RefreshOutlined';
import InboxOutlinedIcon from '@material-ui/icons/InboxOutlined';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import NavigateBeforeOutlinedIcon from '@material-ui/icons/NavigateBeforeOutlined';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import WatchLaterOutlinedIcon from '@material-ui/icons/WatchLaterOutlined';
import SampleEmail from './sampleEmail';


const Emails = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);


  return (
    <>
      <DashboardLayout title="Emails" pageTitle="Emails">
        <ThemeProvider theme={theme}>

          <div className="dashboard-containor">
            <div className="container-with-sidebar">
              <h3 className='heading'> Emails </h3>

              <Tabs defaultIndex={activeTabIndex}>

                <div className="project-sidebar">
                  <TabList>
                    <Tab value='1'> <Box py={1} pl={'6px'}><InboxOutlinedIcon /></Box> <Link to={'/emails'} >Inbox</Link></Tab>
                    <Tab value='1'> <Box py={1} pl={'6px'}><SendOutlinedIcon /></Box> <Link to={'/emails'} >Sent</Link></Tab>
                  </TabList>
                </div>

                <div className="container-sidebar-right">
                  <TabPanel value='1'>
                    <Box>
                      <Box pb={1} mt={-5} mb={2} sx={{ borderBottom: '1px solid #EDECF5', }} className="top-filter-area">
                        <Grid container spacing={1}>
                          <Grid item md>
                            <Grid container spacing={(1)} alignItems="center" >
                              <Grid item>
                                <Box pl={'15px'}>
                                  <Checkbox color="primary" />
                                </Box>
                              </Grid>
                              <Grid item>
                                <IconButton title='Archive' >
                                  <ArchiveOutlinedIcon />
                                </IconButton>
                              </Grid>
                              <Grid item>
                                <IconButton title='Delete' >
                                  <DeleteOutlineOutlinedIcon />
                                </IconButton>
                              </Grid>
                              <Grid item>
                                <IconButton title='Refresh' >
                                  <RefreshOutlinedIcon />
                                </IconButton>
                              </Grid>
                              <Grid item>
                                <Box className='options' style={{ minWidth: '50px', maxWidth: '50px' }}>
                                  {' '}
                                  <div className='options-button'>
                                    <IconButton title='More Options' >
                                      <MoreVertOutlinedIcon />
                                    </IconButton>
                                    {' '}
                                  </div>
                                  <div className="options-list" style={{ right: '10px' }}>
                                    <div>
                                      Option 1
                                    </div>
                                    <hr />
                                    <div>
                                      Option 2
                                    </div>
                                  </div>
                                </Box>
                              </Grid>



                            </Grid>
                          </Grid>
                          <Grid item>
                            <Grid container spacing={1.25} className="team-dash-right">
                              <Grid item className="">
                                <form >
                                  <TextField
                                    name="searchText"
                                    placeholder={'Search'}
                                    className="search"
                                    size="small"
                                    // value={searchText}
                                    // onChange={this.handleChange}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <img alt="" src={SearchIcon} />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                  <button className='d-none' type="submit">
                                    <img src={SearchIconBar} alt="" />
                                  </button>
                                </form>
                              </Grid>


                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                      <TableContainer className="list-table" style={{ maxHeight: "calc(100vh - 200px)" }}>
                        <Table className="table">
                          <TableBody>
                            <TableRow style={{ cursor: 'pointer' }}>
                              <TableCell className='checkbox '>
                                <Checkbox color="primary" /></TableCell>
                              <TableCell className='long-text bold'> Sender Name</TableCell>


                              <TableCell className='long-text' style={{ maxWidth: 'unset' }}>
                                <Box className="long-text" style={{ maxWidth: '100%' }}>
                                  <span className='bold'>Email Heading Title </span> - Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                </Box>
                              </TableCell>
                              <TableCell className='' style={{ minWidth: '90px', maxWidth: '90px' }}>Sep 17</TableCell>
                              <TableCell className='options' style={{ minWidth: '50px', maxWidth: '50px' }}>
                                {' '}
                                <div className='options-button'>
                                  <img alt="" src={MoreIcon} />
                                  {' '}
                                </div>
                                <div className="options-list" style={{ right: '10px' }}>
                                  <div>
                                    Archive
                                  </div>
                                  <div>
                                    Mark as unread
                                  </div>
                                  <hr />
                                  <div style={{ color: '#ff4170' }}>
                                    Delete
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                            <TableRow style={{ cursor: 'pointer' }}>
                              <TableCell className='checkbox '>
                                <Checkbox color="primary" /></TableCell>
                              <TableCell className='long-text bold'> Sender Name</TableCell>


                              <TableCell className='long-text' style={{ maxWidth: 'unset' }}>
                                <Box className="long-text" style={{ maxWidth: '100%' }}>
                                  <span className='bold'>Email Heading Title </span> - Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                </Box>
                              </TableCell>
                              <TableCell className='' style={{ minWidth: '90px', maxWidth: '90px' }}>Sep 17</TableCell>
                              <TableCell className='options' style={{ minWidth: '50px', maxWidth: '50px' }}>
                                {' '}
                                <div className='options-button'>
                                  <img alt="" src={MoreIcon} />
                                  {' '}
                                </div>
                                <div className="options-list" style={{ right: '10px' }}>
                                  <div>
                                    Archive
                                  </div>
                                  <div>
                                    Mark as unread
                                  </div>
                                  <hr />
                                  <div style={{ color: '#ff4170' }}>
                                    Delete
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                            <TableRow style={{ cursor: 'pointer' }}>
                              <TableCell className='checkbox '>
                                <Checkbox color="primary" /></TableCell>
                              <TableCell className='long-text bold'> Sender Name</TableCell>


                              <TableCell className='long-text' style={{ maxWidth: 'unset' }}>
                                <Box className="long-text" style={{ maxWidth: '100%' }}>
                                  <span className='bold'>Email Heading Title </span> - Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                </Box>
                              </TableCell>
                              <TableCell className='' style={{ minWidth: '90px', maxWidth: '90px' }}>Sep 17</TableCell>
                              <TableCell className='options' style={{ minWidth: '50px', maxWidth: '50px' }}>
                                {' '}
                                <div className='options-button'>
                                  <img alt="" src={MoreIcon} />
                                  {' '}
                                </div>
                                <div className="options-list" style={{ right: '10px' }}>
                                  <div>
                                    Archive
                                  </div>
                                  <div>
                                    Mark as unread
                                  </div>
                                  <hr />
                                  <div style={{ color: '#ff4170' }}>
                                    Delete
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                            <TableRow style={{ cursor: 'pointer' }}>
                              <TableCell className='checkbox '>
                                <Checkbox color="primary" /></TableCell>
                              <TableCell className='long-text bold'> Sender Name</TableCell>


                              <TableCell className='long-text' style={{ maxWidth: 'unset' }}>
                                <Box className="long-text" style={{ maxWidth: '100%' }}>
                                  <span className='bold'>Email Heading Title </span> - Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                </Box>
                              </TableCell>
                              <TableCell className='' style={{ minWidth: '90px', maxWidth: '90px' }}>Sep 17</TableCell>
                              <TableCell className='options' style={{ minWidth: '50px', maxWidth: '50px' }}>
                                {' '}
                                <div className='options-button'>
                                  <img alt="" src={MoreIcon} />
                                  {' '}
                                </div>
                                <div className="options-list" style={{ right: '10px' }}>
                                  <div>
                                    Archive
                                  </div>
                                  <div>
                                    Mark as unread
                                  </div>
                                  <hr />
                                  <div style={{ color: '#ff4170' }}>
                                    Delete
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                            <TableRow style={{ cursor: 'pointer' }}>
                              <TableCell className='checkbox '>
                                <Checkbox color="primary" /></TableCell>
                              <TableCell className='long-text bold'> Sender Name</TableCell>


                              <TableCell className='long-text' style={{ maxWidth: 'unset' }}>
                                <Box className="long-text" style={{ maxWidth: '100%' }}>
                                  <span className='bold'>Email Heading Title </span> - Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                </Box>
                              </TableCell>
                              <TableCell className='' style={{ minWidth: '90px', maxWidth: '90px' }}>Sep 17</TableCell>
                              <TableCell className='options' style={{ minWidth: '50px', maxWidth: '50px' }}>
                                {' '}
                                <div className='options-button'>
                                  <img alt="" src={MoreIcon} />
                                  {' '}
                                </div>
                                <div className="options-list" style={{ right: '10px' }}>
                                  <div>
                                    Archive
                                  </div>
                                  <div>
                                    Mark as unread
                                  </div>
                                  <hr />
                                  <div style={{ color: '#ff4170' }}>
                                    Delete
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                            <TableRow style={{ cursor: 'pointer' }}>
                              <TableCell className='checkbox '>
                                <Checkbox color="primary" /></TableCell>
                              <TableCell className='long-text bold'> Sender Name</TableCell>


                              <TableCell className='long-text' style={{ maxWidth: 'unset' }}>
                                <Box className="long-text" style={{ maxWidth: '100%' }}>
                                  <span className='bold'>Email Heading Title </span> - Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                </Box>
                              </TableCell>
                              <TableCell className='' style={{ minWidth: '90px', maxWidth: '90px' }}>Sep 17</TableCell>
                              <TableCell className='options' style={{ minWidth: '50px', maxWidth: '50px' }}>
                                {' '}
                                <div className='options-button'>
                                  <img alt="" src={MoreIcon} />
                                  {' '}
                                </div>
                                <div className="options-list" style={{ right: '10px' }}>
                                  <div>
                                    Archive
                                  </div>
                                  <div>
                                    Mark as unread
                                  </div>
                                  <hr />
                                  <div style={{ color: '#ff4170' }}>
                                    Delete
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                            <TableRow style={{ cursor: 'pointer' }}>
                              <TableCell className='checkbox '>
                                <Checkbox color="primary" /></TableCell>
                              <TableCell className='long-text bold'> Sender Name</TableCell>


                              <TableCell className='long-text' style={{ maxWidth: 'unset' }}>
                                <Box className="long-text" style={{ maxWidth: '100%' }}>
                                  <span className='bold'>Email Heading Title </span> - Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                </Box>
                              </TableCell>
                              <TableCell className='' style={{ minWidth: '90px', maxWidth: '90px' }}>Sep 17</TableCell>
                              <TableCell className='options' style={{ minWidth: '50px', maxWidth: '50px' }}>
                                {' '}
                                <div className='options-button'>
                                  <img alt="" src={MoreIcon} />
                                  {' '}
                                </div>
                                <div className="options-list" style={{ right: '10px' }}>
                                  <div>
                                    Archive
                                  </div>
                                  <div>
                                    Mark as unread
                                  </div>
                                  <hr />
                                  <div style={{ color: '#ff4170' }}>
                                    Delete
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                            <TableRow style={{ cursor: 'pointer' }}>
                              <TableCell className='checkbox '>
                                <Checkbox color="primary" /></TableCell>
                              <TableCell className='long-text bold'> Sender Name</TableCell>


                              <TableCell className='long-text' style={{ maxWidth: 'unset' }}>
                                <Box className="long-text" style={{ maxWidth: '100%' }}>
                                  <span className='bold'>Email Heading Title </span> - Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                </Box>
                              </TableCell>
                              <TableCell className='' style={{ minWidth: '90px', maxWidth: '90px' }}>Sep 17</TableCell>
                              <TableCell className='options' style={{ minWidth: '50px', maxWidth: '50px' }}>
                                {' '}
                                <div className='options-button'>
                                  <img alt="" src={MoreIcon} />
                                  {' '}
                                </div>
                                <div className="options-list" style={{ right: '10px' }}>
                                  <div>
                                    Archive
                                  </div>
                                  <div>
                                    Mark as unread
                                  </div>
                                  <hr />
                                  <div style={{ color: '#ff4170' }}>
                                    Delete
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                          </TableBody>

                        </Table>
                      </TableContainer>
                      
                    </Box>

                  </TabPanel>
                  <TabPanel value='2'>
                    <Box>
                      <Box pb={1} mt={-5} mb={2} sx={{ borderBottom: '1px solid #EDECF5', }} className="top-filter-area">
                        <Grid container spacing={1} alignItems="center">
                          <Grid item md>
                            <Grid container spacing={(1)} alignItems="center" >

                              <Grid item>
                                <IconButton size='medium' title='Back' >
                                  <KeyboardBackspaceOutlinedIcon />
                                </IconButton>
                              </Grid>
                              <Grid item>
                                <IconButton size='medium' title='Archive' >
                                  <ArchiveOutlinedIcon />
                                </IconButton>
                              </Grid>
                              <Grid item>
                                <IconButton size='medium' title='Delete' >
                                  <DeleteOutlineOutlinedIcon />
                                </IconButton>
                              </Grid>
                              <Grid item>
                                <IconButton size='medium' title='Refresh' >
                                  <RefreshOutlinedIcon />
                                </IconButton>
                              </Grid>
                              <Grid item>
                                <Box className='options' style={{ minWidth: '50px', maxWidth: '50px' }}>
                                  {' '}
                                  <div className='options-button'>
                                    <IconButton size='medium' title='More Options' >
                                      <MoreVertOutlinedIcon />
                                    </IconButton>
                                    {' '}
                                  </div>
                                  <div className="options-list" style={{ right: '10px' }}>
                                    <div>
                                      Option 1
                                    </div>
                                    <hr />
                                    <div>
                                      Option 2
                                    </div>
                                  </div>
                                </Box>
                              </Grid>



                            </Grid>
                          </Grid>
                          <Grid item>
                            <Grid container spacing={1} className="team-dash-right">
                              <Grid item>
                                <span>1,417 of 28,317</span>
                              </Grid>
                              <Grid item>
                                <IconButton size='small' title='Previous' >
                                  <NavigateBeforeOutlinedIcon />
                                </IconButton>
                              </Grid>
                              <Grid item>
                                <IconButton size='small' title='Next' >
                                  <NavigateNextOutlinedIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>

                      <Box className="email-container" >
                        <Box className="header" pb={2} mb={1} sx={{ borderBottom: '1px solid #EDECF5', }}>
                          <Box className="subject subtitle">
                            Event Reminder
                          </Box>
                          <Stack spacing={1} direction={'row'} alignItems={'center'}>
                            <PersonOutlineOutlinedIcon />
                            <Box><span className='bold'>Sender Name </span>
                              <span>&lt;</span>usersemail@domain.com<span>&gt;</span></Box>
                          </Stack>
                          <Stack spacing={1} direction={'row'} alignItems={'center'}>
                            <WatchLaterOutlinedIcon />
                            <Box>
                              Friday, October 04, 2024 9:51:34 AM
                            </Box>
                          </Stack>
                        </Box>

                        <Box className="email-body">
                          <SampleEmail />
                        </Box>

                      </Box>
                    </Box>
                  </TabPanel>
                </div>
              </Tabs>
            </div>
          </div>
        </ThemeProvider>
      </DashboardLayout>
    </>
  );
};

export default Emails;
